window.ga = window.ga || function () {
    (ga.q = ga.q || []).push(arguments)
};
ga.l = +new Date;
ga('create', 'UA-66868843-1', {'legacyHistoryImport': false, 'allowLinker': true});
ga('set', 'hostname', 'mercurius.io');
ga('require', 'linker');
ga('linker:autoLink', ['advisor.mercurius.io','trader.mercurius.io','tradr.mercurius.io'] );
ga('require', 'ec');


module.exports = function (){
    return window.ga(arguments);
}