let ga = require('./ga_init')
const fbq = require('./fbq_init')
const rdt = require('./rdt_init')
const gaEvents = [];
const fbEvents = [];
const rdtEvents = [];

//Calbacks, quando gli script asincroni si caricano, spara gli eventi
(function retryTen() {
    if ((typeof fbq !== 'undefined') && (fbq.version > '2.9'))
        fbEvents.forEach(sendWithFbq)
    else
        setTimeout(retryTen, 10);
})();

(function loadReddit() {
    if (typeof rdt !== 'undefined')
        rdtEvents.forEach(sendWithRdt)
    else
        setTimeout(loadReddit, 10);
})();

ga(function(){
    gaEvents.forEach(sendWithGa)
});
//Fine Calbacks

function attachGaTracker(body) {
    let trackedObj = body.querySelectorAll("*[data-eventaction]");
    trackedObj.forEach(function (tracked) {
        tracked.addEventListener("click", function (evt) {
            let track = this;
            let category = track.getAttribute('data-eventcategory');
            let action = track.getAttribute('data-eventaction');
            let label = track.getAttribute('data-eventlabel');

            let data = {hitType: 'event'};
            if (category != null && category != "") {
                data["eventCategory"] = category
            }

            if (action != null && action != "") {
                data["eventAction"] = action
            }

            if (label != null && label != "") {
                data["eventLabel"] = label
            }
            enqueueOrNotifyGa({
                action: 'send',
                payload: data
            });

        });

    });

}

const enqueueOrNotifyGa = function(event){
    if (ga==null)
        gaEvents.push(event)
     else
        sendWithGa(event);
};

const enqueueOrNotifyFbq = function(event){
    if (fbq==null)
        fbEvents.push(event)
    else
        sendWithFbq(event);
};

const enqueueOrNotifyRdt = function(event){
    if (rdt==null)
        rdtEvents.push(event)
    else
        sendWithRdt(event);
};

function sendWithFbq(event) {
    fbq('trackCustom', event.action, event.payload);
}

function sendWithRdt(event) {
    rdt('track', event);
}

function sendWithGa(event) {
    if (event.payload instanceof Array){
        ga(event.action, event.payload[0], event.payload[1])
    } else{
        ga(event.action, event.payload)
    }
}

function bindGa(e_ga){
    ga = e_ga;
}

module.exports = {
    init: attachGaTracker,
    appendGa: enqueueOrNotifyGa,
    appendFqb: enqueueOrNotifyFbq,
    appendRdt: enqueueOrNotifyRdt,
    bindGa: bindGa
};