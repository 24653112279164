// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
const TurboLinks = require("turbolinks");
TurboLinks.start()
require("@rails/activestorage").start()
//require("channels")
require("what-input");
const $script = require("scriptjs");
const Tracker = require("./tracking");

require("../components/freshsales");
$script("https://www.googleoptimize.com/optimize.js?id=OPT-PCKSCQK",'Optimizer');
$script("https://www.google-analytics.com/analytics.js", function (){
    Tracker.bindGa(ga);
    Tracker.appendGa({action:'send', payload: 'pageview'});
});
$script("//cdn.freshmarketer.com/611981/1640996.js",'FM');

$(document).on('ready turbolinks:load', function() {
    $(document).foundation();
    Tracker.init(document.querySelector('body'));
});


if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

module.exports={
    Tracker: Tracker,
    $script: $script,
    TurboLinks: TurboLinks
}
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)