export function initForm (formId, Tracker){
	console.log('Caricato Download File', Tracker, formId)

	let id = "#" + formId + "Form";
	let form = $(id);

	const urlParams = new URLSearchParams(window.location.search);

	form.on('formvalid.zf.abide', function (e) {

		let email = form.find('#email').val();
		let fileUrl = form.data('url');

		const properties = {
			'email': email,
			'First name': form.find('#name').val(),
			'Last name': form.find('#surname').val(),
			'Web form': formId+"Download"
		};

		let event_name = "Downloaded " + formId;
		let event_properties = {
			'url': window.location.toString(),
			'email': email
		};

		//console.log(event_properties);

		const campaign = urlParams.get('utm_campaign')
		if(campaign){
			properties['Campaign'] = campaign;
			properties['Utm campaign'] = campaign;
		}

		const source = urlParams.get('utm_source')
		if(source){
			properties['Source'] = source;
			event_properties['Source'] = source;
		}

		const content = urlParams.get('utm_content')
		if(content){
			properties['Utm Content'] = content;
		}

		const adsetname = urlParams.get('utm_adsetname')
		if(adsetname){
			properties['Adset name'] = adsetname;
			event_properties['Adset name'] = adsetname;

		}

		const medium = urlParams.get('utm_medium')
		if(medium){
			properties['Medium'] = medium;
		}

		freshsales.identify(email, properties, function (){
			FM.associateVisitor(email);
			window.open(fileUrl,'_blank')
			let modal = $("#"+formId+"Modal");
			if(modal.length){
				modal.foundation('close');
			}
		});

		//console.log(event_properties);
		freshsales.trackEvent(event_name,  event_properties);

		Tracker.appendGa({
			action: 'send',
			payload: {
				hitType: 'event',
				eventCategory: formId,
				eventAction:'download'
			}
		})

		//per adesso su reddit logghiamo solo il download del whitepaper
		if(formId === 'whitePaper'){
			Tracker.appendRdt('ViewContent')
		}

	})
	.on('submit',function (){
		return false;
	});
}